import React, { FC } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import ScrollToNextSectionBtn from "../../components/ScrollToNextSectionBtn";
import Carousel from "../../components/Carousel";
import CommitmentCard from "../../components/CommitmentCard";
import BrandCard from "../../components/BrandCard";
import { BrandScreenData } from "../BrandsScreen/data";
import { CommitmentCardsData } from "./data";

export interface CommitmentProps {
    id: string;
    nextSectionId: string;
}

const Commitment: FC<CommitmentProps> = ({ id }) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div
            id={id}
            className='smScreens:pt-[15vh] h-screen relative bg-cover bg-no-repeat pt-16 commitment overflow-x-hidden	'>
            <h3 className='text-2xl md:text-3xl text-gold font-bold container-padding'>
                OUR COMMITMENT
            </h3>
            <Carousel
                hasGradient={!isMobile}
                hasGapBtnSlides
                hasArrows={!isMobile}
                isArrowDark
                hasProgressBar
                progressBarInMobView
                hasFullWidthProgressBars={!isMobile}
                classes='my-10 h-[65vh] md:h-auto overflow-auto md:overflow-hidden'>
                {CommitmentCardsData.map((data, id) => {
                    return <CommitmentCard {...data} />;
                })}
            </Carousel>
        </div>
    );
};

export default Commitment;

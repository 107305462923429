import React, { ReactNode, FC } from 'react';
import { headerHeight } from './Header';
import GibsonHeaderLogo from './Header/GibsonHeaderLogo';
import Spacer from './Spacer';

export interface PageSectionProps {
    hasFixedHeight?: boolean;
    children?: ReactNode;
    classes?: string;
    hasHeaderAtBottom?: boolean;
    bgColorClass?: string;
    snapAtEnd?: boolean;
    extendsBg?: boolean;
}

const PageSection: FC<PageSectionProps> = ({
    children,
    classes = "",
    hasHeaderAtBottom = false,
    hasFixedHeight = true,
    extendsBg = false,
    bgColorClass,
    snapAtEnd=false,
}) => {
    const PageSectionStyle = {
        height: hasFixedHeight ? `100vh` : "",
    };

    return (
        <div
            className={`${hasFixedHeight ? "h-screen" : ""}  ${
                snapAtEnd ? "snap-end" : "snap-start"
            }  transition-all	 duration-1000 scroll-smooth snap-always			${classes} ${bgColorClass}`}
            style={PageSectionStyle}>
            {!hasHeaderAtBottom && !extendsBg && (
                <Spacer height={headerHeight} classes={bgColorClass} />
            )}
            {children}
            {hasHeaderAtBottom && !extendsBg && (
                <Spacer height={headerHeight} classes={bgColorClass} />
            )}
        </div>
    );
};

export default PageSection;
import React from "react";

export const ScrollIcon = ({ color = "black" }) => (
    <svg
        width='38'
        height='37'
        viewBox='0 0 38 37'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='scroll'>
            <rect
                id='Rectangle Copy 9'
                x='18.2598'
                y='14.06'
                width='0.74'
                height='3.7'
                rx='0.37'
                stroke={color}
            />
            <rect
                id='Rectangle'
                x='13.8203'
                y='11.1'
                width='9.62'
                height='15.54'
                rx='4.81'
                stroke={color}
                stroke-width='2'
            />
            <circle id='Oval' cx='19' cy='18.5' r='18' stroke={color} />
        </g>
    </svg>
);

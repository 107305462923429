import HomePageTab from "../../assets/images/HomePageTab.jpg";
import * as React from "react";
import Button from "../../components/Button";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { sectionIds } from ".";
import BrandCard from "../../components/BrandCard";
import Carousel from "../../components/Carousel";
import { BrandScreenData } from "../BrandsScreen/data";
import { brandBooks } from "./data";

export interface AllBrandsProps {
    id: string;
}


const AllBrands: React.FC<AllBrandsProps> = ({id}) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <>
            <Carousel
                hasArrows
                isVertical={isMobile}
                isInfiniteScroll
                hasSlideDivider
                classes=''>
                {BrandScreenData(isMobile).map((data, id) => {
                    return <BrandCard {...data} isOnDesktop />;
                })}
            </Carousel>
            <div className='flex flex-row justify-center py-16 container-padding'>
                <div className='w-[28rem] flex flex-col items-center'>
                    <h3 className='text-3xl	font-bold uppercase pb-4'>
                        DOWNLOAD our brand book
                    </h3>
                    <p className='text-center text-sm pb-12'>
                        Gibson Brands is proud to serve the world. Click on a
                        language below to download a translated PDF of the
                        Gibson Brand Book.{" "}
                    </p>
                    <Button
                        isGold
                        hasArrow
                        externalLink='https://brandfolder.com/s/2hqw67whmnbj54jm6g8shs3'>
                        visit library
                    </Button>
                    {/* <div className='grid grid-cols-[auto_auto] md:grid-cols-[auto_auto_auto_auto] '>
                        {brandBooks.map((data) => {
                            return (
                                <div className='flex flex-row items-center'>
                                    <img
                                        src={data.image}
                                        width='23px'
                                        height='23px'
                                    />
                                    <Button
                                        hasArrow
                                        arrowColor='#000000'
                                        classes='pl-[0.8rem]'>
                                        {data.name}
                                    </Button>
                                </div>
                            );
                        })}
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default AllBrands;

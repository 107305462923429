import HistoryImage from "../../assets/images/History.jpg";
import React, { FC } from "react";
import Spacer from "../../components/Spacer";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import ScrollToNextSectionBtn from "../../components/ScrollToNextSectionBtn";

export interface MissionProps {
    nextSectionId: string;
    id: string;
    classes?: string;
    title: string;
    desc: string;
}

const Mission: FC<MissionProps> = ({ nextSectionId, id, classes, desc, title}) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div
            id={id}
            className={`smScreens:pt-[15vh] h-screen relative bg-cover bg-no-repeat ${classes} pt-16`}>
            <h3 className='text-2xl md:text-3xl text-gold font-bold'>
                {title}
            </h3>
            <h1 className='text-white text-[42px] md:text-6xl xl:text-[4rem] md:w-[40rem] xl:w-[46rem] h-[62%] md:h-auto overflow-auto md:overflow-hidden leading-[46px] font-bold mt-[12vh] md:mt-[8vh] uppercase'>
                {desc}
            </h1>
            <div className='absolute w-full flex justify-center bottom-[4vh] md:bottom-[11vh] left-0 scrollToSection'>
                <ScrollToNextSectionBtn
                    nextSectionLink={nextSectionId}
                    color='white'
                />
            </div>
        </div>
    );
};

export default Mission;

import HistoryImage from "../../assets/images/History.jpg";
import React, { FC } from "react";
import ValuesHeadshotDesktop from "../../assets/images/Desktop/valuesHeadshot.png";
import ValuesHeadshotMobile from "../../assets/images/Mobile/valuesHeadshot.png";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import ScrollToNextSectionBtn from "../../components/ScrollToNextSectionBtn";
import { IconA } from "../../assets/svg/Desktop/IconA";
import { IconB } from "../../assets/svg/Desktop/IconB";
import { IconD } from "../../assets/svg/Desktop/IconD";
import { IconE } from "../../assets/svg/Desktop/IconE";
import { IconG } from "../../assets/svg/Desktop/IconG";

export interface ValuesProps {
    nextSectionId: string;
    id: string;
}

export interface ValueCardProps {
    title: string;
    desc: string;
    icon: React.ReactNode;
    logoPos?: "left" | "right";
}

const ValueCard: FC<ValueCardProps> = ({
    title,
    desc,
    icon,
    logoPos = "right",
}) => {
    return (
        <div
            className={`flex gap-4 items-center justify-end	lg:justify-between lg:max-w-[16rem] ${
                logoPos === "right"
                    ? "flex-row items-end text-end"
                    : "flex-row-reverse"
            }`}>
            <div className='flex flex-col '>
                <h6 className='text-base lg:text-lg'>{title}</h6>
                <p className='text-xs lg:text-sm'>{desc}</p>
            </div>
            <div>{icon}</div>
        </div>
    );
};

const valueCardData = [
    {
        title: "DEPENDABLE",
        desc: "Building trust for our brands, our business, our products, our stakeholders, and our team",
        icon: <IconD />,
    },
    {
        title: "AUTHENTIC",
        desc: "Staying true to our iconic past and our innovative future",
        icon: <IconA />,
    },
    {
        title: "EMPATHETIC",
        desc: "Considering all perspectives and acknowledging different points of view",
        icon: <IconE />,
    },
    {
        title: "GROWING",
        desc: "Focusing on the growth of our brands, our business, our teams, and our people",
        icon: <IconG />,
    },
    {
        title: "BOLD",
        desc: "Taking control of our results with a confident, calculated, and courageous approach",
        icon: <IconB />,
    },
    {
        title: "ENGAGED",
        desc: "Expecting everyone to be “all in” with the right balance of passion, perseverance, and professionalism",
        icon: <IconE />,
    },
];

const Values: FC<ValuesProps> = ({ nextSectionId, id }) => {
    const { isMobile, isSmallScreen } = useIsMobileViewport();
    return (
        <div
            id={id}
            className=' smScreens:pt-[15vh] h-screen relative bg-cover bg-no-repeat values pt-16 overflow-x-hidden	'>
            <h3 className='text-2xl lg:text-3xl text-gold font-bold'>
                OUR SHARED VALUES
            </h3>
            <div className='guitar relative top-[-3rem] flex w-full justify-start lg:justify-center h-[90%] lg:bottom-[16vh] text-white'>
                <div className='flex flex-col relative top-[11vh] max-w-[60vw] lg:max-w-none gap-[2vh] lg:gap-[3vh] z-[1] h-[76%] lg:h-auto overflow-auto  lg:overflow-hidden'>
                    {valueCardData.map((item, key) => {
                        if (isSmallScreen || key < valueCardData.length / 2)
                            return (
                                <ValueCard
                                    title={item.title}
                                    desc={item.desc}
                                    icon={item.icon}
                                    logoPos={isSmallScreen ? "left" : "right"}
                                />
                            );
                    })}
                </div>
                <img
                    src={ValuesHeadshotDesktop}
                    className='absolute max-w-fit lg:static h-[90%] bottom-[-10vh] lg:bottom-[16vh] left-[50%] lg:left-auto z-0'
                />
                {!isSmallScreen && (
                    <div className='flex flex-col relative top-[11vh] gap-[3vh]'>
                        {valueCardData.map((item, key) => {
                            if (key >= valueCardData.length / 2)
                                return (
                                    <ValueCard
                                        title={item.title}
                                        desc={item.desc}
                                        icon={item.icon}
                                        logoPos='left'
                                    />
                                );
                        })}
                    </div>
                )}
            </div>
            <div className='absolute w-full flex justify-center bottom-[4vh] lg:bottom-[11vh] left-0 scrollToSection'>
                <ScrollToNextSectionBtn
                    nextSectionLink={nextSectionId}
                    color='white'
                />
            </div>
        </div>
    );
};

export default Values;

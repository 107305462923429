import HomePageTab from "../../assets/images/HomePageTab.jpg";
import * as React from "react";
import Button from "../../components/Button";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { sectionIds } from ".";

const StepInsideBlock = () => {
    return (
        <div
            className={`relative md:absolute md:bg-white justify-between flex stepInsideBlock items-end md:items-auto`}>
            <div className='h-full flex justify-center md:justify-evenly flex-col relative uppercase pr-8 w-6/12'>
                <h4 className='uppercase text-2xl font-bold leading-7'>
                    Experience the Virtual Garage
                </h4>
                <Button
                    hasArrow
                    classes='self-start p-0'
                    arrowColor='#000000'
                    externalLink='https://www.gibsongarage.com/'>
                    step inside
                </Button>
            </div>
            <img
                src={HomePageTab}
                className='object-cover object-right w-6/12'
            />
        </div>
    );
}

const WhoWeAre = () => {
    const { isMobile } = useIsMobileViewport();
    return (
        <>
            <div
                className={`h-screen relative bg-cover bg-no-repeat whoWeAre bg-right`}>
                <div
                    className={`text-white md:gap-4 flex flex-col relative details h-full justify-end pb-16 md:pb-0 md:justify-start`}>
                    <h3 className='uppercase text-xl md:text-4xl'>
                        Gibson Brands
                    </h3>
                    <h1 className='uppercase text-7xl md:text-9xl font-bold'>
                        who we are
                    </h1>
                    <p className='text-sm w-[85%] md:w-[100%]'>
                        Innovative and dependable. Bold and authentic. Deeply
                        rooted and growing.
                        {!isMobile && <br />} Our band of musical brands remains
                        the pinnacle of creative craftsmanship.
                    </p>
                    <Button
                        isGold
                        hasArrow
                        classes='self-start mt-6'
                        scroll
                        internalLink={`#${sectionIds.history}`}>
                        discover More
                    </Button>
                </div>
                {!isMobile && <StepInsideBlock />}
            </div>
            {isMobile && <StepInsideBlock />}
        </>
    );
};

export default WhoWeAre;

import React, { FC, useState } from "react";
import { MinusSign } from "../../assets/svg/MinusSign";
import { PlusSign } from "../../assets/svg/PlusSign";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Button from "../Button";
import { CommitmentCardProps, SecondaryCommitmentCardProps } from "./types";
import * as styles from "./commitmentCard.module.sass";

const SecondaryCommitmentCard: FC<SecondaryCommitmentCardProps> = (props) => {
    const { title, details, img, link, linkPlaceHolder, shrinkFn, isShrinked } = props;
    const { isMobile } = useIsMobileViewport();

    if (isMobile && isShrinked)
        return (
            <div
                className={`relative flex w-full p-3.5 justify-between max-w-[340px] h-[54px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.2)] items-center rounded-b-lg`}>
                <p className='text-sm'>{title}</p>
                <div className='cursor-pointer' onClick={shrinkFn}>
                    {isShrinked ? <PlusSign /> : <MinusSign />}
                </div>
            </div>
        );

    if(!isShrinked)
    return (
        <div
            className={`flex flex-col max-w-[340px] md:max-w-max md:w-[407px] md:h-[454px] p-3.5 md:p-6 md:pt-12 pb-0 md:pb-0 shadow-[2px_2px_10px_0px_rgba(0,0,0,0.2)] rounded-r-lg`}>
            <img src={img} className='pb-2.5 md:pb-4' />
            <div className='relative flex flex-col pb-12 h-full'>
                <h2 className='text-[22px] md:text-3xl	uppercase font-bold'>
                    {title}
                </h2>
                <p className='text-xs mt-1 md:mt-4'>{details}</p>
                <div className='absolute flex justify-between bottom-0 right-0 w-full pb-4'>
                    <Button
                        classes='p-0 text-gold underline'
                        arrowColor='#84734D'
                        hasArrow
                        externalLink={link}>
                        {linkPlaceHolder}
                    </Button>
                    <div className='cursor-pointer' onClick={shrinkFn}>
                        {isShrinked ? <PlusSign /> : <MinusSign />}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div
            className={`relative flex flex-col w-[44px] h-[454px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.2)] items-center justify-center rounded-r-lg`}>
            <p className='vertical-text'>{title}</p>
            <div className='absolute flex bottom-0 right-0 w-full pb-4 justify-center'>
                <div className='cursor-pointer' onClick={shrinkFn}>
                    {isShrinked ? <PlusSign /> : <MinusSign />}
                </div>
            </div>{" "}
        </div>
    );
};

const CommitmentCard: FC<CommitmentCardProps> = (props) => {
    const { title, details, heading, img, link, linkPlaceHolder, secondaryCards } = props;
    const { isMobile } = useIsMobileViewport();
    const [isShrinked, setIsShrinked] = useState<boolean[]>([
        false,
        ...new Array(secondaryCards ? secondaryCards.length : 0).fill(true),
    ]);

    const shrinkReq = (index: number) => {
        
        setIsShrinked((e) => {
            // let newShrinkArr = isMobile ? new Array(
            //     secondaryCards ? secondaryCards.length + 1 : 0
            // ).fill(true) : [...e];
            let newShrinkArr = new Array(
                secondaryCards ? secondaryCards.length + 1 : 0
            ).fill(true);
            newShrinkArr[index] = !e[index];

            if (
                isMobile &&
                newShrinkArr.every((val) => val === true)
            ) {
                newShrinkArr[0] = false;
            }
            
            return newShrinkArr;
        });
    }

    return (
        <div
            className={`flex flex-col items-center md:items-start md:flex-row ${
                isMobile ? "relative top-[-8px] w-[94vw] max-w-[340px]" : ""
            }`}>
            {isMobile && isShrinked[0] ? (
                <div className='flex flex-col p-3.5 max-w-[340px] w-full shadow-[2px_2px_10px_0px_rgba(0,0,0,0.2)] z-0'>
                    <h6 className='uppercase text-sm md:text-base'>{title}</h6>
                    <h1 className='uppercase text-4xl md:text-5xl font-bold	'>
                        {heading}
                    </h1>
                </div>
            ) : (
                <div className='md:h-[464px]'>
                    <div className='flex flex-col max-w-[340px] md:max-w-max md:w-[407px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.2)] z-0'>
                        <img src={img} className='mobile:max-h-[171px] md:h-[222px]' />
                        <div
                            className={`relative flex flex-col p-3.5 md:p-6 pb-12 ${
                                secondaryCards?.length
                                    ? "mobile:max-h-[160px] "
                                    : "mobile:max-h-[220px] "
                            }max-h-auto md:h-[232px] overflow-auto  md:overflow-hidden`}>
                            <h6 className='uppercase text-sm md:text-base'>
                                {title}
                            </h6>
                            <h1 className='uppercase text-4xl md:text-5xl font-bold	'>
                                {heading}
                            </h1>
                            <p className='text-xs mt-1 md:mt-4'>{details}</p>
                            {link ||
                                (linkPlaceHolder && (
                                    <div className='relative flex justify-start bottom-[-1rem] top-[1rem] right-0 w-full pb-4'>
                                        <Button
                                            classes='p-0'
                                            arrowColor='#000000'
                                            hasArrow
                                            externalLink={link}>
                                            {linkPlaceHolder}
                                        </Button>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
            {secondaryCards?.map((data, index) => {
                return (
                    <SecondaryCommitmentCard
                        key={index}
                        {...data}
                        shrinkFn={() => shrinkReq(index + 1)}
                        isShrinked={isShrinked[index + 1]}
                    />
                );
            })}
        </div>
    );
};

export default CommitmentCard;

import React from "react";

export const PlusSign = () => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <ellipse
            cx='11'
            cy='11'
            rx='11'
            ry='11'
            transform='rotate(90 11 11)'
            fill='#86764E'
        />
        <path
            d='M11.6346 10.3654L11.6346 6L10.3654 6L10.3654 10.3654L6 10.3654L6 11.6346L10.3654 11.6346L10.3654 16L11.6346 16L11.6346 11.6346L16 11.6346L16 10.3654L11.6346 10.3654Z'
            fill='white'
        />
    </svg>
);
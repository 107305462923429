import Image1 from '../../assets/images/Desktop/HomePage/Commitment/Image1.jpg';

import Image2 from "../../assets/images/Desktop/HomePage/Commitment/Image2.png";
import Image2_1 from "../../assets/images/Desktop/HomePage/Commitment/Image2_1.jpg";
import Image2_2 from "../../assets/images/Desktop/HomePage/Commitment/Image2_2.png";
import Image2_3 from "../../assets/images/Desktop/HomePage/Commitment/Image2_3.png";
import Image2_4 from "../../assets/images/Desktop/HomePage/Commitment/Image2_4.png";

import Image3 from "../../assets/images/Desktop/HomePage/Commitment/Image3.jpg";
import Image3_1 from "../../assets/images/Desktop/HomePage/Commitment/Image3_1.png";
import Image3_2 from "../../assets/images/Desktop/HomePage/Commitment/Image3_2.png";
import Image3_3 from "../../assets/images/Desktop/HomePage/Commitment/Image3_3.png";
import Image3_4 from "../../assets/images/Desktop/HomePage/Commitment/Image3_4.png";

import Image4 from "../../assets/images/Desktop/HomePage/Commitment/Image4.jpg";
import Image4_1 from "../../assets/images/Desktop/HomePage/Commitment/Image4_1.png";
import Image4_2 from "../../assets/images/Desktop/HomePage/Commitment/Image4_2.png";

import Image5 from "../../assets/images/Desktop/HomePage/Commitment/Image5.png";

import EnglishBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/EnglishBook.png";
import GermanBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/GermanBook.png";
import JapaneseBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/JapaneseBook.png";
import ItalianBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/ItalianBook.png";
import SpanishBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/SpanishBook.png";
import FrenchBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/FrenchBook.png";
import PortugueseBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/PortugueseBook.png";
import ChineseBook from "../../assets/images/Desktop/HomePage/BrandBookLangs/ChineseBook.png";

import { CommitmentCardProps } from '../../components/CommitmentCard/types';

export const CommitmentCardsData: CommitmentCardProps[] = [
    {
        title: "IN SERVICE TO",
        heading: "THE ARTIST",
        details:
            "Artists have been performing with our instruments dating back to the turn of the 20th century, when Orville Gibson was crafting them by hand. In 1928, we partnered with popular jazz performer Nick Lucas to design a Gibson guitar that fit his style.",
        img: Image1,
        // link: "",
        // linkPlaceHolder: "LEARN MORE",
    },
    {
        title: "IN SERVICE TO",
        heading: "THE MUSICIAN",
        details:
            "The moment an instrument leaves one of our craftories, it begins a journey to inspire its player’s expression, creativity, and unique sound for a lifetime. We surround our instrument owners within an omni-channel ecosystem designed to support all their needs — to educate, engage, entertain, experience, endorse. ",
        img: Image2,
        secondaryCards: [
            {
                title: "GIBSON APP",
                details:
                    "We engineered a Gibson App to bring the world of guitar to your smartphone. Download this digital companion and you’ll never be without a high-value tuner, complete with automatic string detection and loads of alternate tunings. ",
                img: Image2_1,
                link: "https://www.gibson.com/en-US/Gibson-App",
                linkPlaceHolder: "get the gibson app",
            },
            {
                title: "GIBSON CUSTOMER SERVICE",
                details:
                    "Whether you recently purchased your first Gibson or need help with a vintage instrument, you’ve come to the right place. We stand behind our products and our customers throughout their long lifetimes.",
                img: Image2_2,
                link: "https://www.gibson.com/en-US/Support/Customer-Service",
                linkPlaceHolder: "how can we help?",
            },
            {
                title: "VIRTUAL GUITAR TECH",
                details:
                    "Through one-on-one, real-time video chats, our technicians can help you tune up, change strings, clean and condition your fretboard. They’ll guide you through adjusting your truss rod, pickup height, action and intonation.",
                img: Image2_3,
                link: "https://www.gibson.com/en-US/Support/Virtual-Guitar-Tech",
                linkPlaceHolder: "SCHEDULE A CONSULTATION",
            },
            {
                title: "REPAIR AND RESTORATION",
                details:
                    "We fix them like we build them: with craftsmanship and care. Gibson’s in-house Repair & Restoration shop can take any out-of-warranty instrument and work with the owner to bring it back to life - or even elevate it to new glory.",
                img: Image2_4,
                link: "https://www.gibson.com/en-US/Support/Virtual-Repair-And-Restoration",
                linkPlaceHolder: "SCHEDULE A CONSULTATION",
            },
        ],
    },
    {
        title: "IN SERVICE TO",
        heading: "MUSIC LOVERS",
        details:
            "Our heritage is crafting the best instruments and gear in the world, for the best musicians on the planet. But that’s just the beginning of who our work touches. Our instruments have been used as creative tools to shape sound across genres, empowering innovations in expression and iconic music that inspired generational movements. ",
        img: Image3,
        secondaryCards: [
            {
                title: "GIBSON GARAGE",
                details:
                    "Housed in the historic Cummins Station in Nashville, Tennessee, the Gibson Garage is the new must-see destination in the heart of Music City. Explore all Gibson brands, there’s something at the Gibson Garage for every music lover.",
                img: Image3_1,
                link: "https://www.gibson.com/en-US/garage",
                linkPlaceHolder: "Visit the Garage",
            },
            {
                title: "GIBSON TV",
                details:
                    "Launched in 2020, Gibson TV presents original, cinematic quality content that celebrates all things Guitar, Music, and Culture.",
                img: Image3_2,
                link: "https://www.gibson.com/en-US/GibsonTV",
                linkPlaceHolder: "visit gibson TV",
            },
            {
                title: "GIBSON RECORDS",
                details:
                    "Guitars. Music. Culture. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                img: Image3_3,
                link: "https://www.gibson.com/en-US/News/gibson-records-gibson-announces-launch-of-record-label",
                linkPlaceHolder: "see gibson records",
            },
            {
                title: "GIBSON PUBLISHING",
                details:
                    "This premium coffee table book explores in unprecedented detail the instruments Gibson Global Brand Ambassador and rock legend Slash has used on countless hit records and on stages worldwide.",
                img: Image3_4,
                link: "https://www.gibson.com/en-US/slashbook",
                linkPlaceHolder: "see slash book",
            },
        ],
    },
    {
        title: "IN SERVICE TO",
        heading: "OUR PARTNERS",
        details:
            "Similar to the way we support our artist and fan communities, we serve the very select and authorized commercial partners who represent our brands around the globe through an omni-channel experience. This premium network is the pinnacle for supplying exciting and innovative new Gibson Brands products to engaged fans across the world.",
        img: Image5,
    },
    {
        title: "IN SERVICE TO",
        heading: "THE COMMUNITY",
        details:
            "Since its relaunch in 2019, Gibson Gives – a 501(c)(3) – has raised over $2.5 million worldwide, and gifted thousands of instruments in pursuit of our mission of making music matter more, one guitar at a time. ",
        img: Image4,
        secondaryCards: [
            {
                title: "GIBSON GIVES",
                details:
                    "In addition to our brand-specific programs, we have created several purpose-driven initiatives under Gibson Gives that channel the inspiring generosity, empathy, and sense of purpose shared by our employees and the broader music community. ",
                img: Image4_1,
                link: "/gibson-gives",
                linkPlaceHolder: "visit gibson gives",
            },
            {
                title: "GIBSON GENERATION GROUP",
                details:
                    "Our commitment to nurturing young talent drove the creation of our two-year mentoring program.",
                img: Image4_2,
                link: "http://g3.gibson.com/",
                linkPlaceHolder: "visit gibson gives",
            },
        ],
    },
];


export const brandBooks = [
    {
        name: "English",
        link: "",
        image: EnglishBook,
    }
    // ,
    // {
    //     name: "German",
    //     link: "",
    //     image: GermanBook,
    // },
    // {
    //     name: "Japanese",
    //     link: "",
    //     image: JapaneseBook,
    // },
    // {
    //     name: "Italian",
    //     link: "",
    //     image: ItalianBook,
    // },
    // {
    //     name: "Spanish",
    //     link: "",
    //     image: SpanishBook,
    // },
    // {
    //     name: "French",
    //     link: "",
    //     image: FrenchBook,
    // },
    // {
    //     name: "Portuguese",
    //     link: "",
    //     image: PortugueseBook,
    // },
    // {
    //     name: "Chinese",
    //     link: "",
    //     image: ChineseBook,
    // },
];

import React from "react";

export const ScrollIcon = ({ color = "black" }) => (
    <svg
        width='28'
        height='27'
        viewBox='0 0 28 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='scroll'>
            <circle id='Oval' cx='14' cy='13.5' r='13' stroke={color} />
            <path
                id='Arrow 1'
                d='M13.6464 19.3536C13.8417 19.5488 14.1583 19.5488 14.3536 19.3536L17.5355 16.1716C17.7308 15.9763 17.7308 15.6597 17.5355 15.4645C17.3403 15.2692 17.0237 15.2692 16.8284 15.4645L14 18.2929L11.1716 15.4645C10.9763 15.2692 10.6597 15.2692 10.4645 15.4645C10.2692 15.6597 10.2692 15.9763 10.4645 16.1716L13.6464 19.3536ZM14.5 7C14.5 6.72386 14.2761 6.5 14 6.5C13.7239 6.5 13.5 6.72386 13.5 7L14.5 7ZM14.5 19L14.5 7L13.5 7L13.5 19L14.5 19Z'
                fill={color}
            />
        </g>
    </svg>
);  
import React from "react";

export const MinusSign = () => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='11' cy='11' r='11' fill='#86764E' />
        <path d='M16 12V10H6V12H16Z' fill='white' />
    </svg>
);

import React, { ReactNode, FC, useState } from "react";
import { ScrollIcon as ScrollIconDesktop } from "../../assets/svg/Desktop/ScrollIcon";
import { ScrollIcon as ScrollIconMobile } from "../../assets/svg/Mobile/ScrollIcon";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Button from "../Button";
import * as styles from "./header.module.sass";

export interface ScrollToNextSectionBtnProps {
    classes?: string;
    color?: string;
    nextSectionLink: string;
}


const ScrollToNextSectionBtn: FC<ScrollToNextSectionBtnProps> = ({
    classes,
    nextSectionLink,
    color,
}) => {
    const { isMobile } = useIsMobileViewport();

    return (
        <Button
            scroll
            internalLink={`#${nextSectionLink}`}
            classes={`flex flex-col text-xs text-${color} w-[12rem] font-normal gap-[0.7rem] ${classes}`}>
            {isMobile ? (
                <ScrollIconMobile color={color} />
            ) : (
                <ScrollIconDesktop color={color} />
            )}
            <p>scroll to continue</p>
        </Button>
    );
};

export default ScrollToNextSectionBtn;

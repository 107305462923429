import React, { FC } from "react";
import Header from "../../components/Header";
import PageSection from "../../components/PageSection";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import WhoWeAre from "./WhoWeAre";
import History from "./History";
import "./index.sass";
import Mission from "./Mission";
import Values from "./Values";
import Commitment from "./Commitment";
import Footer from "../../components/Footer";
import AllBrands from "./AllBrands";

export interface HomeScreenProps {}

export const sectionIds = {
    history: "history",
    mission1: "mission1",
    mission2: "mission2",
    mission3: "mission3",
    values: "values",
    commitment: "commitment",
    allBrands: "allbrands",
    footer: "footer",
};

export const isOverflowing = (element: Element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const HomeScreen: FC<HomeScreenProps> = (props) => {
    const { isMobile, isSmallScreen } = useIsMobileViewport();
    return (
        <div className='overflow-x-hidden home-screen snap-y h-screen overflow-y-scroll snap-always	overscroll-contain	 transition-all	 duration-1000	scroll-smooth	'>
            {/* <GibsonHeaderLogo /> */}
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={!isMobile}
                classes='relative'
                extendsBg>
                <WhoWeAre />
            </PageSection>
            <Header shrinkOnScroll />
            <PageSection bgColorClass='bg-white'>
                <History
                    id={sectionIds.history}
                    nextSectionId={sectionIds.mission1}
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={isMobile}
                extendsBg={isMobile}>
                <Mission
                    id={sectionIds.mission1}
                    nextSectionId={sectionIds.mission2}
                    classes='mission1'
                    title='OUR SHARED MISSION'
                    desc={
                        "TO LEVERAGE OUR PORTFOLIO OF BRANDS TO INFLUENCE GLOBAL CULTURE AND OWN THE LARGEST SHARE OF SOUND FOR THE NEXT 125 YEARS"
                    }
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={isMobile}
                extendsBg={isMobile}>
                <Mission
                    id={sectionIds.mission2}
                    nextSectionId={sectionIds.mission3}
                    title='OUR SHARED VISION'
                    classes='mission2'
                    desc='TO BECOME THE MOST RELEVANT, THE MOST PLAYED, AND THE MOST LOVED MUSIC BRANDS IN THE WORLD'
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={isMobile}
                extendsBg={isMobile}>
                <Mission
                    id={sectionIds.mission3}
                    nextSectionId={sectionIds.values}
                    classes='mission3'
                    title='OUR SHARED MODE'
                    desc='LEVERAGE TO LEAD'
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-black'
                hasHeaderAtBottom={isSmallScreen}
                extendsBg={isSmallScreen}>
                <Values
                    id={sectionIds.values}
                    nextSectionId={sectionIds.commitment}
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={isMobile}
                extendsBg={isMobile}>
                <Commitment
                    id={sectionIds.commitment}
                    nextSectionId={sectionIds.allBrands}
                />
            </PageSection>
            <PageSection
                bgColorClass='bg-white'
                hasHeaderAtBottom={isMobile}
                extendsBg={isMobile}
                hasFixedHeight={false}>
                <div
                    id={sectionIds.allBrands}
                    className='relative bg-cover bg-no-repeat  overflow-x-hidden	'>
                    <AllBrands id={sectionIds.allBrands} />
                    <Footer />
                </div>
            </PageSection>
        </div>
    );
};

export default HomeScreen;

import HistoryImage from "../../assets/images/History.jpg";
import React, { FC } from "react";
import Spacer from "../../components/Spacer";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import ScrollToNextSectionBtn from "../../components/ScrollToNextSectionBtn";
import "./index.sass";
import Button from "../../components/Button";

export interface HistoryProps {
    nextSectionId: string;
    id: string;
}

const History: FC<HistoryProps> = ({ nextSectionId,id }) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div
            id={id}
            className={`h-screen relative flex flex-col md:flex-row history pt-0 md:pt-16 justify-start items-center md:items-start md:justify-evenly`}>
            <div
                className={`flex flex-col justify-start title bg-contain bg-no-repeat pt-4 md:pt-0 h-[28%] md:h-full gap-4 md:gap-0`}>
                <h1 className='uppercase font-bold text-5xl w-[100%] md:text-6xl md:w-[18rem] lg:text-7xl md:!leading-[15vh]'>
                    SHAPING SOUND{" "}
                </h1>
                <h1 className='uppercase font-bold text-5xl w-[100%] md:text-6xl md:w-[18rem] lg:text-7xl md:!leading-[15vh]'>
                    since 1894{" "}
                </h1>
            </div>
            <div
                className={`flex flex-col justify-between h-[45%] overflow-auto  md:overflow-hidden md:h-[72vh] details md:w-[35rem] md:max-w-[23rem] mt-4`}>
                <p className='text-sm max-h-[60vh] '>
                    We are the leading iconic guitar brand that continues to
                    shape the sounds of generations of musicians and music
                    lovers across genres. Founded in 1894 and headquartered in
                    Nashville, Gibson Brands has a legacy of world-class
                    craftsmanship, legendary music partnerships, and progressive
                    product evolution that is unrivaled among musical instrument
                    companies.
                    <Spacer height={"1rem"} />
                    The Gibson Brands portfolio includes Gibson, the leading
                    guitar brand, as well as many of the most beloved and
                    recognizable music brands. Gibson Brands is dedicated to
                    quality, innovation, and sound excellence so that music
                    lovers for generations to come will continue to experience
                    music shaped by Gibson Brands.Each of our brands boasts a
                    unique identity, a compelling story to tell, and a shared
                    promise to support and inspire the world of music, its
                    players, and fans.
                </p>
            </div>
            {!isMobile && (
                <div
                    className={`flex flex-col justify-start image items-start pl-10 mt-8`}>
                    <Button
                        hasArrow
                        classes='self-start p-0 imgVerticalText'
                        arrowClasses='rotate-180 !w-[20px] !left-[-2px]'
                        arrowColor='#000000'
                        internalLink='/overview/gibson'>
                        Discover Our Brand History
                    </Button>
                    <img src={HistoryImage} className='max-h-[70vh]' />
                    <p className="mt-[0.5rem]">Orville H. Gibson - 1890’s</p>
                </div>
            )}
            <ScrollToNextSectionBtn
                nextSectionLink={nextSectionId}
                classes='absolute bottom-[12vh]'
            />
        </div>
    );
};

export default History;

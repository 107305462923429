import React from "react";
import type { HeadFC } from "gatsby";
import HomeScreen from "../screens/HomeScreen";

const IndexPage = () => {
    return (
        <div>
            <HomeScreen />
            <div id='portal' />
        </div>
    );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
